import axios from "axios";
// import router from "@/router";
// import store from "@/store";
import { awsConfig } from "@/services/constants";

const getInstance = async () => {
    const instance = axios.create({
        baseURL: `${awsConfig.URL}`,
        headers: {'Content-Type': `application/json`},
        timeout: 60000
    });
    return instance;
};

const integrateInfo = async (action, servicename, arg1, arg2, arg3, arg4, arg5, arg6, returnData=false) => {
    const instance = await getInstance();
    let payload = {
        "action": action, //"integrate",
        "servicename": servicename,
        "arg1": arg1,
        "arg2": arg2,
        "arg3": arg3,
        "arg4": arg4,
        "arg5": arg5,
        "arg6": arg6
    };

    var result = false;
    await instance
    .post("integrateInfo", payload)
    .then(response => {
        // console.log(response.data);
        if (returnData)
            result = response.data
        else
            result = response.data.statusCode == 200;
        // if (result.data.statusCode == 200)
        //     alert("success")
        // else
        //     alert("not success")
    })
    .catch(error => {
        console.log(error);
    })
    return result;
};

//******************** */
export default {
    integrateInfo
};