<template>
  <v-container>
    <template v-if="loading">
      <v-row
        width="100%"
        class="fill-height ma-0"
        align="center"
        justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
          width="6"
          size="64"
        ></v-progress-circular>
      </v-row>
    </template>

    <div v-else-if="arg3 == '1'">
      <div class="div_normal mb-5">Your refund voucher will be available in your Villa Market Shop Online account within 2 business days. Simply click on the “Select Voucher” link next to Refund voucher on the final checkout page to choose which vouchers you want to use with your order.</div>
      <v-row justify="center">
        <v-img
          alt=""
          class="shrink"
          contain
          width="300"
          max-width="350"
          :src="require('@/assets/select_voucher.jpg')"
          transition="scale-transition"/>
      </v-row>
    </div>

    <div class="div_normal" v-else-if="arg3 == '2' && promptPayNo">
      <div>Thank you, we will process your refund against your prompt pay account:</div>
      <div class="div_promptpayno">{{promptPayNo.substr(0,2)}}-{{promptPayNo.substr(2,4)}}-{{promptPayNo.substr(6,4)}}</div>
      <div>Note that your transfer will be made within 3 business days.</div>
    </div>
    <div class="div_normal" v-else-if="arg3 == '2' && !isConfirmPromptPay">
      <v-row class="mt-3" justify="center">
        <div width="200px" class="div_phonenumber">Phone Number</div>
      </v-row>
      <v-row class="mt-5" justify="center">
        <div width="200px">
          <v-text-field
            class="inputNumber"
            type="number"
            outlined
            v-model="phoneNumber"
            autocomplete="phoneNumber"
            placeholder="Input Phone Number"
            label=""></v-text-field>
        </div>
      </v-row>
      <v-row class="mt-0" justify="center">
        <div width="200px">
          <v-btn
            rounded
            color="primary"
            width="150"
            :disabled="!phoneNumber || phoneNumber.trim().length!=10"
            @click="confirmPromptPay">Send
          </v-btn>
        </div>
      </v-row>
      <div class="div_normal mt-5">Please enter the number associated with your PromptPay account so that we can transfer your refund.</div>
    </div>
    <div class="div_normal" v-else-if="arg3 == '2' && isConfirmPromptPay">Thank you, your prompt pay details have been recorded. Note that your transfer will be made within 3 business days.</div>

    <div class="div_normal" v-else-if="arg3 == '3'">Thank you for confirming your choice of normal bank process for your refund. Note that your transfer can take between 15-45 days to appear in your account, depending on your bank’s refund process.</div>

    <div class="div_normal" v-else>Not have args.</div>
  </v-container>
</template>
  
<script>
import awsService from "@/services/aws_service";

  export default {
    name: "Integrate",
    data() {
      return {
        servicename: "",
        arg1: "",
        arg2: "",
        arg3: "",
        arg4: "",
        arg5: "",
        arg6: "",
        promptPayNo: "",
        phoneNumber: "",
        isConfirmPromptPay: false,
        loading: false
      };
    },
    // components: {
    // },
    async mounted() {
      try {
        this.servicename = this.$route.params.servicename ?? "";
        this.arg1 = this.$route.params.arg1 ?? "";
        this.arg2 = this.$route.params.arg2 ?? "";
        this.arg3 = this.$route.params.arg3 ?? "";
        this.arg4 = this.$route.params.arg4 ?? "";
        this.arg5 = this.$route.params.arg5 ?? "";
        this.arg6 = this.$route.params.arg6 ?? "";

        this.promptPayNo = "";
        if (this.arg3 == "1" || this.arg3 == "3") {
          let result = await this.updateIntegrate('integrate', false);
          if (result)
            console.log("save success.")
          else
            alert("save not success.");
            // console.log("save not success.")
        }
        else
        if (this.arg3 == "2" && this.arg2 != "0") {
          let result = await this.updateIntegrate("getpromptpay", true);
          console.log("result", result)
          if (result?.statusCode == 200) {
            this.promptPayNo = result?.promptPayNo
            console.log("save success.", result?.promptPayNo ?? "")
          }
          else
            alert("save not success.");
            // console.log("save not success.")
        }
      } catch (error) {
        alert(error);
      }
    },
    methods: {
      async updateIntegrate(action, returnData)
      {
        return await awsService.integrateInfo(action, this.servicename, this.arg1, this.arg2, this.arg3, this.arg4, this.arg5, this.arg6, returnData);
      },
      async confirmPromptPay() {
        try {
          if (this.phoneNumber.trim()=="" || this.phoneNumber.trim().length!=10) {
            alert("Invalid Phone Number.");
            return;
          }
          this.loading = true;
          this.arg4 = this.phoneNumber;
          let result = await this.updateIntegrate('integrate', false);
          if (result) {
            console.log("save success.");
            this.isConfirmPromptPay = true;
          }
          else
            alert("save not success.");
        } catch (error) {
          alert(error);
        }
        finally {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        }
      }
    }
  };
</script>

<style scoped>
.div_center {
  text-align: center;
}
.div_normal {
  font-size: 20px;
  font-weight: normal;
  text-align: center;
}
.div_promptpayno {
  font-size: 30px;
  font-weight: bold;
  background-color: #FFF59D;
  color: #3F51B5;
}
.div_phonenumber {
  font-size: 20px;
  color: #3F51B5;
}
.header {
  color: #3F51B5;
}
.inputNumber >>> input[type='number'] {
  -moz-appearance:textfield;
}
.inputNumber >>> input::-webkit-outer-spin-button,
.inputNumber >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>